import React, { useState } from "react"
import Layout from "../components/layout"
import FullPost from "../components/fullpost"
import { graphql } from "gatsby"
import PostsMap from "../components/postsmap"

export const query = graphql`
  query {
    posts: allMarkdownRemark(
      filter: {
        frontmatter: { latitude: { ne: null }, longitude: { ne: null } }
      }
    ) {
      nodes {
        ...PostMapMarkerFragment
        ...FullPostFragment
      }
    }
  }
`

const GlobePage = ({ data }) => {
  const [selectedNode, updateSelectedNode] = useState(null)

  return (
    <Layout>
      <div className="content-area">
        <main className="site-main">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs12 no-padd">
                <PostsMap
                  posts={data.posts.nodes}
                  onPostClicked={post => {
                    updateSelectedNode(post)
                    // Need to wait until React has rendered the new element.
                    if (post !== null) {
                      setTimeout(() => {
                        var scroll = require("smooth-scroll")()
                        var anchor = document.querySelector("#post")
                        scroll.animateScroll(anchor, undefined, {
                          offset: 140,
                          updateURL: false,
                        })
                      }, 1)
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div className="bg-c-1 padd-lg-b60">
            <div className="container padd-only-sm">
              <div className="row" />
            </div>
          </div>

          {selectedNode && (
            <div className="bg-c-1 padd-lg-b60">
              <div className="container">
                <div className="row">
                  <div className="col-xs-12" id="post">
                    <FullPost post={selectedNode} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </Layout>
  )
}

export default GlobePage
